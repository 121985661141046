import React from 'react';
import { graphql } from 'gatsby';
import TwoColumns from '../components/TwoColumns';
import Link from '../components/Link';
import Header from '../components/HeaderUpdated';
import Footer from '../components/Footer';
import Image from '../components/Image';

export default ({ data }) => {
  const { textCard, image } = data.wp.options.options.fourOhFourPage;
  return (
    <div>
      <Header />
      <section className="pad-tb--sm">
        <div className="container">
          <TwoColumns
            text={
              <div className="text-card max-w-md">
                <h1
                  className="text-card__heading text-58px"
                  dangerouslySetInnerHTML={{
                    __html: textCard.heading,
                  }}
                />
                <div className="text-card__content" dangerouslySetInnerHTML={{ __html: textCard.content }} />

                <Link to={textCard.button.url} className="button button--h57 button--waves">
                  {textCard.button.title}
                </Link>
              </div>
            }
            image={
              <div className="o-ratio">
                <div className="o-ratio__content">
                  <Image data={image} className="object-cover" />
                </div>
              </div>
            }
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export const query = graphql`
  {
    wp {
      options {
        options {
          fourOhFourPage {
            image {
              ...Image
            }
            textCard {
              content
              heading
              button {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;
